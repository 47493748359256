import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './site.actions';
import * as api from './site.api';

export function* logout() {
	try {
		yield call(api.logout);
		yield put(actions.logoutComplete());
		window.history.pushState(null, '', '/core?r=site/login');
		window.onpopstate = () => {
			window.history.pushState(null, '', '/core?r=site/login');
		};
	} catch (err) {
		yield put(actions.logoutFailed(err));
		toast.error('There was an error trying to logout.');
	}
}

function* saga() {
	yield takeLatest(actions.LOGOUT, logout);
}

export default saga;
